import React from 'react';
import { Link } from 'gatsby';
import AppLayout from '../components/layout/AppLayout';

const NotFoundPage: React.FC = () => (
  <AppLayout>
    <h1>Oops! 404</h1>
    <h3>You just hit a route that doesn&#39;t exist.</h3>
    <Link to="/">Go Home</Link>
  </AppLayout>
);

export default NotFoundPage;
